<template>
  <div id="afboard" class="afboard">
    <div class="box-top" v-if="role.isAfOwnerBoth && role.isReport">
      <div class="fixed">
        <div class="box-jump" @click="$router.push({name: 'BoardCountry'})">销售平台
          <img src="@/assets/i_change.png"/>
        </div>
      </div>
    </div>
  <div class="box-filter-afboard">
      <div class="row">
        <div class="item">
          <a-select v-model="curKey" style="width: 3.5rem;"
            placeholder="日期"
             @change="changeCurrent"
            :options="filterOpts"
            />
        </div>
        <div class="item">
          <a-select v-model="query.company_type_id"
            placeholder="门店类型"
            :allowClear="(optList.companyTypeOtps || []).length>1"
            @change="onSearch()"
            :options="optList.companyTypeOtps"/>
        </div>
        <div v-if="page!='afStore'" class="item" style="text-align: center;">
          <span :style="`color: ${showToStore ? '#0070C0': '#000'}`"
             @click="showToStore=true"> 直达门店 </span>
        </div>
        <span class="btn" @click="popFilter"><img class="ic" src="@/assets/af_i_filter.png" />筛选</span>
      </div>
      <div v-if="dateSel" class="custom" style="margin-top: 0.5rem;">
          <div class="date">
          <el-date-picker
            v-model="query.start_date"
            type="date"
            align="center"
            prefix-icon=" "
            @change="changeDate"
            value-format="yyyy-MM-dd"
            placeholder="开始日期">
          </el-date-picker>
          <span>至</span>
          <el-date-picker
            v-model="query.end_date"
            type="date"
            align="center"
            prefix-icon=" "
            @change="changeDate"
            value-format="yyyy-MM-dd"
            placeholder="结束日期">
          </el-date-picker>
          </div>
        </div>
      <div v-if="showToStore" class="box-to-store">
        <div style="color: #000; margin-right: 0.5rem">门店代码</div>
        <a-select v-model="company_id" showSearch
          placeholder="请输入门店代码或店名"
          :allowClear="true"
          :filterOption="filterOption"
          :options="companyOpts"/>
        <div class="btn">
          <a @click="jumpToStore">去门店</a>
          <span class="btn" @click="showToStore=false;company_id=undefined">取消</span>
        </div>
      </div>
      
    </div>
    <a-drawer
      title="筛选"
      placement="bottom"
      wrapClassName="pop-filter-afboard"
      :closable="true"
      :visible="showFilter"
      :handle="true" 
      height="90%"
      @close="hideFilter"
    >

    <div class="box-search-board">
    <a-form-model :model="query" layout="vertical"
     :label-col="{span: 9}"
     :wrapper-col="{span: 15}" >

      <CascadeFilter ref="cFilter"
        :placeholder="placeholder"
        :hideRegion="hideRegion"
        :hideArea="hideArea"
        :hideCompany="hideCompany"
        :hideDepartment="true"
        :showDealerGroup="false"
        :showDealerCode="true"
        :defaultData="query"
        :defaultList="optList"
        @change="onFilterChange"/>
      
      <a-form-model-item label="门店类型">
          <a-select v-model="query.company_type_id"
            placeholder="请选择门店类型"
            :allowClear="(optList.companyTypeOtps || []).length>1"
            :options="optList.companyTypeOtps"/>
      </a-form-model-item>
      <a-form-model-item label="开单日期">
        <div class="custom">
          <div class="date">
          <el-date-picker
            v-model="query.start_date"
            type="date"
            align="center"
            prefix-icon=" "
            value-format="yyyy-MM-dd"
            placeholder="开始日期">
          </el-date-picker>
          <span>至</span>
          <el-date-picker
            v-model="query.end_date"
            type="date"
            align="center"
            prefix-icon=" "
            value-format="yyyy-MM-dd"
            placeholder="结束日期">
          </el-date-picker>
          </div>
        </div>
      </a-form-model-item>
    </a-form-model>
      <div v-if="showFilter" class="box-btn">
          <span class="reset" @click="onReset">
            重置
          </span>
          <span class="search" @click="onSearch()">
            查询
          </span>
        </div>
    </div>
    </a-drawer>
    <router-view ref="child" :key="this.$route.path"/>
    <NavBar tab="售后分析"></NavBar>
  </div>
</template>
<script>
import { getOptions } from "@/dbCommon/api/common";
import {getCookie} from "@/dbCommon/utils/cookie.js";
import NavBar from '@/components/afNavbarOwner.vue';
import CascadeFilter from '@/components/CascadeFilter'
import {normalDate, toRange} from '@/utils/formatDate'
import actions from '@/api/action'
import pick from 'lodash.pick'
import { postForm } from '@/api'
import Vue from 'vue'
import {getRole} from '@/utils/getRole';


export default {
  data() {
    return {
      curKey: "yesterday",
      dateSel: false,
      showFilter: false,
      mescroll: null,
      mescrollDown: {
        use: false,
        auto: false,
      },
      sellerOtps: [],
      role: getRole(),
      pageSize: 20,
      total: 10,
      disabled: false,
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      optList: {
      },
      exceptiontype:false,
      query: {
      },
      params: {
      },
      rangeDate: null,
      radioDate: null,
      state: false,
      target_model:[],
      filterOpts: [
        { key: "month", label: "本月" },
        { key: "week", label: "本周" },
        { key: "yesterday", label: "本日" },
        { key: "lastMonth", label: "上月" },
        { key: "custom", label: "自定义" },
      ],
      brand_id:'',
      placeholder: {
        crop: '请选择集团',
        dealerGroup: '请选择经销商集团',
        brand: '请选择品牌',
        region: '请选择大区',
        area: '请选择小区',
        company: '请选择公司',
        companyType: '请选择门店类型',
        department: '请选择部门',
      },
      showToStore: false,
      company_id: undefined,
      companyOpts: [],
      hideRegion: true,
      hideArea: true,
      hideCompany: true,
      page: '',
    }
  },
  computed: {
    
  },
  components:{
    NavBar,
    CascadeFilter,
  },
  watch: {
    $route: {
      handler() {
        this.showToStore = false;

        const {name:page, query} = this.$route;
        this.page = page;
        this.query = {...StringToNum(query)};
        this.$refs.cFilter && this.$refs.cFilter.loadData();

        if (page == 'afRegion') {
          this.hideRegion = false;
          this.hideArea = true;
          this.hideCompany = true;
        }
        if (page == 'afArea') {
          this.hideRegion = false;
          this.hideArea = false;
          this.hideCompany = true;
        }
        if (page == 'afStore') {
          this.hideRegion = false;
          this.hideArea = false;
          this.hideCompany = false;
        }
      },

      immediate: true,
    },
  },
  methods: {
    jumpToStore() {
      this.query={...this.query,company_id: this.company_id};
      this.$router.push({name: 'afStore', query: {...this.query}});
    },
    changeCurrent(key) {
      if (key == 'custom') {
        this.dateSel = true;
        this.query = {
          ...this.query,
          start_date: '',
          end_date: '',
        };
        return;
      }
      this.dateSel = false;
      const rd = normalDate(toRange(key));
      this.query = {
        ...this.query,
        start_date: rd[0],
        end_date: rd[1],
      };
      this.onSearch();
    },
    changeDate() {
      this.onSearch();
    },
    hideFilter() {
      this.showFilter = false;
      this.query = {...this.tempQ};
      this.optList = {...this.tempL};
    },
    popFilter() {
      this.showFilter = true;
      this.tempQ = {...this.query};
      this.tempL = {...this.optList};
    },
    async onFilterChange(query, optList) {
      const {regionOtps, areaOtps, companyOtps, companyTypeOtps} = optList || {};
      this.query = {
        ...this.query,
        ...query,
      }
      var brand_id = query.brand_id
      var company_id = query.company_id
      this.optList = {
        ...this.optList,
        regionOtps: regionOtps || [],
        areaOtps: areaOtps || [],
        companyOtps: companyOtps || [],
        companyTypeOtps: companyTypeOtps || [],
      }
      
    },
    onReset() {
      this.query = {};
      this.rangeDate=null;
      this.radioDate=null;
      this.$refs.cFilter.reset();
      this.query = {
        ...this.query,
      };
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    formatOptionList(list) {
      if (!list) {
        return [];
      }
      return list.filter(o => o).map((o, i) => this.formatOption(String(i), o, o));
    },
    formatOption(key, value, label) {
      return {
        key: String(key), value, label, title: label
      }
    },
    onSearch() {
      this.showFilter = false;
      this.$router.replace({name: this.$route.name, query: {...this.query}});
      this.$refs.child && this.$refs.child.getDataBySearch(this.query);
    },
    async loadStore() {
      const { data: companyOpts } = await postForm('/api/option/index', { table: "company", auth:1});
      this.companyOpts = codeName(companyOpts);
    },
  },
  async created() {
    const {brand_id:brandId, crop_id_default:cropId} = Vue.ls.get('USER_INFO') || {};
    const q = this.$router.currentRoute.query || {};
    if (!q.start_date && !q.end_date) {
      this.curKey = 'yesterday';
      this.changeCurrent(this.curKey);
    }else if (q.start_date && (q.start_date == q.end_date)) {
      this.curKey = 'yesterday';
    } else {
      this.curKey = 'custom';
      this.dateSel = true;
    }
    this.query = {
      ...this.query,
      ...q,
      //crop_id: cropId,
      //brand_id: brandId,
    };
  },
  async mounted () {
    this.optList = {
      ...pick(this.$store.state, [
        'validStateOpts',
      ]),
      companyTypeOtps: await actions.getCompanyTypeList({crop_id: this.query.crop_id}),
    }

    this.loadStore();
  },

};
function codeName(list) {
  return (list || []).map(o => ({
    value: o.id,
    label: String(o.code).toUpperCase() + o.name,
  }));
}

function StringToNum(obj){
  for(let i in obj){
    if(i!='start_date'&&i!="end_date"){
      obj[i] = (isNaN(Number(obj[i])) || !obj[i]) ? obj[i] : Number(obj[i]);
    }

  }
    return obj
}
</script>

<style scoped lang="less">
/deep/.ant-select {
  width: 100%;
    padding-right: 16px;
    position: relative;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #999999;;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  .ant-select-selection {
    border: 0 !important;
    box-shadow:none !important;
    border-radius: 0 !important;
    background: transparent;

    .ant-select-selection__rendered {
      margin: 0;
    }
    .ant-select-selection__placeholder {
      text-align: left;
      color: #999;
      font-size: 0.7rem;
    }
    .ant-select-selection-selected-value {
      width: 100%;
      padding-right: 12px;
      text-align: left;
      color: #000;
      font-size: 0.7rem;
    }
  }
  .ant-select-arrow {
    display: none;
  }
}

.pop-filter-afboard {
  /deep/.ant-drawer-content {
    background: #fff;

    .ant-drawer-wrapper-body {
      display: flex;
      flex-direction: column;
    }

    .ant-drawer-body {
      flex: 1;
      padding: 0.75rem;
      overflow-y: auto;
    }

    .ant-form-item {
      padding-bottom: 0;
      margin-bottom: 1rem;
    }
  }
  /deep/.ant-radio-group {
  }
  /deep/.ant-input {
    padding: 0;
    color: #4F5154;
    font-weight: normal;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #D9D9D9;
    border-radius: 0;
    text-align: left;
    height: 2rem;
    line-height: 2rem;

    &:focus {
      box-shadow: none;
      outline: none;
      border-bottom: 1px solid #D9D9D9;
    }

    &::-webkit-input-placeholder{
      color: #999; 
      font-size: 0.7rem;
    }
  }
  /deep/.ant-select {
    border-bottom: 1px solid #D9D9D9;
    .ant-select-selection {
      background-color: transparent;
      height: 2rem;
      .ant-select-selection__rendered {
        line-height: 2rem;
      }
    }
  }

.custom {
  border-bottom: 1px solid #D9D9D9;
  background: transparent;
  width: 100%;

  /deep/.el-input__inner {
    text-align: center;
    background-color: transparent;
    border: 0;
    padding: 0 0.5rem;
    color: #4F5154;
    height: 2rem;
    line-height: 2rem;
    font-size: 0.7rem;
    &::-webkit-input-placeholder{
      color: #999; 
      font-size: 0.7rem;
      font-weight: normal;
    }
  }
  .date {
    font-size: 0.6rem;
    padding-left: 0.8rem;
    display: flex;
    align-items: center;
    background: url(~@/assets/af_i_date_d.png) no-repeat 0 0.5rem;
    background-size: 1rem 1rem;
    .el-input__inner {
      padding: 0;
    }
  }
}
  .box-search-board {
      color: #4F5154;
      font-size: 0.37333rem;
      padding: 0rem 0 1.5rem 0;

      /deep/ .ant-form-item-label > label {
        color: #666666;
        font-style: normal;
        font-size: 0.7rem;
      }
      .item {
        padding: 0 0.5rem;
        margin-bottom: 0.26666rem;
        overflow: hidden;
        border-radius: 2px;

        >.label {
          margin: 0.1rem 0 0.2rem 0;
        }
      }

      .group-btn {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: -0.3rem;

        .ant-radio-button-wrapper {
          box-shadow: none !important;
          margin: 0.3rem 0 0 2%;
          &:nth-child(3n+1) {
            margin-left: 0;
          }
        }
        >label {
          height: 2rem;
          line-height: 2rem;
          border-radius: 2rem;
          flex: 1;
          text-align: center;
          background: #E5E8EE;
          font-weight: normal;
          min-width: 32%;
          max-width: 32%;
          outline: none;
          border: 0;

          &:before {
            display: none;
          }

          &.ant-radio-button-wrapper-checked {
            background: #0070C0;
            color: #fff;
          }
        }
      }

      .box-btn {
        margin-left: -0.75rem;
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: 750px;
        display: flex;
        span {
          font-size: 0.8rem;
          height: 2.5rem;
          line-height: 2.5rem;
          flex: 1;
          text-align: center;
          font-weight: normal;
          color: #969799;
          background: #F5F5F5;
          &.search {
            color: #fff;
            background: #0070C0;
          }
        }
      }

  }
}

.afboard {
  background: #F2F2F2;
  min-height: 100vh;

.box-top {
  height: 44px;
  position: relative;

  .fixed {
    height: 44px;
    line-height: 44px;
    top: 0;
    width: 100%;
    max-width: 750px;
    z-index: 1000;
    position: fixed;
    background: #F2F2F2 url(/img/af_bg_main_s.83c6e400.png);
    background-size: 100% auto;
  }

  .box-jump {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #0070C0;
    width: 130px;
    margin: auto;

    img {
      height: 15px;
    }
  }
}

  /deep/h3.title {
    font-size: 0.8rem;
    color: #000000;
    margin: 0.5rem 0.75rem;
  }

  /deep/.box-list {
    margin: 0.75rem;
    overflow: auto;
  }

  /deep/.box-core {
    margin: 0.75rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.even {
      div.score:last-child {
        min-width: 100%;
      }
    }

    div.score {
      font-size: 0.7rem;
      padding: 0.6rem 0.5rem;
      width: 49%;
      margin-bottom: 0.35rem;
      background: #fff;
      border-radius: 0.2rem;
      color: #666666;

      strong {
        font-size: 1.1rem;
        margin-right: 0.2rem;
        color: #000000;
      }

      .name {
        margin-top: 0.2rem;
        display: flex;
        align-items: center;
        i {
          margin-right: 0.2rem;
          display: inline-block;
          width: 1rem;
          height: 1rem;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% auto;
        }

        .count {
          background-image: url(~@/assets/af_bd_count.png) ;
        }
        .score {
          background-image: url(~@/assets/af_bd_score.png) ;
        }
        .zhg {
          background-image: url(~@/assets/af_bd_user.png) ;
        }
        .zhg_rate {
          background-image: url(~@/assets/af_bd_zhg.png) ;
        }
        .match_rate {
          background-image: url(~@/assets/af_bd_match.png) ;
        }
        .jsd_rate {
          background-image: url(~@/assets/af_bd_pay.png) ;
        }
        .fcx_rate {
          background-image: url(~@/assets/af_bd_fbx.png) ;
        }
        .bytc_rate {
          background-image: url(~@/assets/af_bytc_rate.png) ;
        }
        .yb_rate {
          background-image: url(~@/assets/af_yb_rate.png) ;
        }
        .i7 {
          background-image: url(~@/assets/af_bd_vhc.png) ;
        }
      }
    }
  }

  /deep/.ant-table-thead > tr {
    th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
        background: #fff;
    }
    th {
      border-radius: 0 !important;
      background: #fff;
      padding: 0.7rem 0.5rem;
      white-space: nowrap;
      border: 0;
      font-size: 0.7rem;
      color: #666;
      font-weight: normal;

      &:first-child {
        background: #E6F1F9;
      }
    }
  }
  /deep/ .ant-table-tbody {
    tr {
      td {
        background: #fff !important;
        border: 0;
        border-top: 1px solid #d9d9d9;
        border-radius: 0 !important;
        padding: 0.5rem;
        white-space: nowrap;
        font-size: 0.7rem;
        &:first-child {
          background: #E6F1F9 !important;
          border-color: #E6F1F9;
        }
      }
    }
    tr:nth-child(odd) {
      background: #fff;
    }
    tr:nth-child(even) {
      background: #fff;
    }
  }

  /deep/.box-filter-afboard {
    background: #F2F2F2 url(~@/assets/af_bg_main_s.png);
    background-size: 100% auto;
    background-attachment: fixed;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.5rem;
    align-items: center;
    color: #000;


    .sel-store{
       color: #0070C0;
    }
    .box-to-store {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #D9D9D9;
      .ant-select {
        width: 50%;
      }
      .btn {
        flex: 1;
        text-align: right;
        a {
           color: #0070C0;
        }
        span {
          color: #666666;
          margin-left: 1rem;
        }
      }
    }
    .custom {
      border-bottom: 1px solid #D9D9D9;
      background: transparent;
      width: 100%;

      .el-input__inner {
        text-align: center;
        background-color: transparent;
        border: 0;
        padding: 0 0.5rem;
        color: #000;
        height: 2rem;
        line-height: 2rem;
        font-size: 0.7rem;
        &::-webkit-input-placeholder{
          color: #999; 
          font-size: 0.7rem;
          font-weight: normal;
        }
      }
      .date {
        font-size: 0.6rem;
        padding-left: 0.8rem;
        display: flex;
        align-items: center;
        background: url(~@/assets/af_i_date_d.png) no-repeat 0 0.5rem;
        background-size: 1rem 1rem;
        .el-input__inner {
          padding: 0;
        }
      }
    }
    
    .row {
      height: 2rem;
      background: transparent;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .item {
        flex: 1;
        overflow: hidden;
        padding-right: 1rem;
        font-size: 0.7rem;
      }
      .item-icon {
        padding-right: 0.5rem;
        font-size: 1rem;
      }
      .btn {
        padding-left: 0.5rem;
        display: inline-block;
        font-size: 0.7rem;
        border-left: 1px solid #D9D9D9;
        text-align: right;
        display: flex;
        align-items: center;

        img {
          height: 0.8rem;
        }
      }
    }
  }
}
</style>
